<template>
  <div class="body positionRelative">
    <img style="width: 100vw;"
         src="https://xworld1.oss-cn-beijing.aliyuncs.com/xworld_images/e76af6ca-80a8-4daa-aae7-ff6fcda41003.png">
    <div class="positionAbsolute" style="top: 0;width: 100vw;">
      <div class="font-size32 flex_able font-colorFFFFFF font-weightBold margin-top30 margin-left30"
           style="height: var(--88);">
        {{ user == 1 ? '收到的红包' : '发出的红包' }}
      </div>
      <div v-for="(item, index) in tabList" :key="index">
        <div class="flex_able flex-directionColumn margin-left30 margin-right30 margin-top30"
             style="background: #FFFFFF;border-radius: var(--20);padding-bottom: var(--30);">

          <div @click="choiceDate" class="flex_able"
               style="width:100%;padding-left: var(--30);padding-top: var(--40);padding-bottom: var(--30);">
            <span style="font-size: var(--28);font-family: PingFang SC;font-weight: 400;color: #999999;">
              {{ selectDate }}
            </span>
            <img style="margin-left:var(--6);width: var(--16);height: var(--9);" src="@/assets/down_arrow.png"/>
          </div>
          <div style="width: 100%;height: calc(100vh - var(--254) - var(--120));overflow: auto">
            <van-list style="width: 100%;" v-model=item.loading :immediate-check="false" :finished=item.finished
                      :finished-text=item.finishedText
                      @load="getTabListContent(index)" v-if="item.contentList.length > 0">
              <div v-for="(item, index) in item.contentList" :key="index"
                   class="margin-left30 margin-right30"
                   style="height: var(--150);background: #FAFAFA;border-radius: var(--20);margin-bottom: var(--20);">
                <div @click="skipRedPackRecordPage(item.totalRedPacketUuid)" class="flex_center"
                     style="justify-content: center;height: var(--148) ">
                  <div
                      style="margin-left: var(--30);margin-right: var(--30);flex:1;font-size: var(--32);font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #1B1B1B;">
                    <div class="oneLine" style="line-height: var(--42)">
                      {{ item.redPackName }}
                    </div>
                    <div
                        style="font-weight: 400;margin-top: var(--6);font-size: var(--28);color: #999999;line-height: var(--40)">
                      {{ item.time }}
                    </div>
                  </div>
                  <div
                      style="margin-right: var(--30);display:flex;flex-direction:column;align-items:end;font-size: var(--30);font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #1B1B1B;">
                    <div class="oneLine"
                         style="color:#E4514F;max-width:var(--350);width: 100%;font-size: var(--30);line-height: var(--42);text-align: right">
                      {{ item.amount }}{{ item.coinName }}
                    </div>
                    <div class="oneLine"
                         style="max-width:var(--350);width: 100%;text-align: right;font-weight: 400;font-size: var(--24);color: #999999;height: var(--34);margin-top: var(--10);">
                      {{ item.alreadyGrabDetail }}
                    </div>
                  </div>
                </div>

              </div>
            </van-list>
            <show-no-data-view v-if="item.contentList.length === 0"/>
          </div>
        </div>


      </div>
    </div>

    <date-choice-dialog type="year-month" ref="controlDateDialog" @handleChange="dateCallback"
                        @handleChangeSelectDate="selectDateCallback"/>
  </div>
</template>


<script>
import {Toast} from 'vant';
import {Api} from "@/utils/net/Api";
import DateChoiceDialog from "@/components/DateChoiceDialog.vue";
import {Constants} from "@/utils/constants";
import ShowNoDataView from "@/components/ShowNoDataView.vue";
import EncryptUtils from "@/utils/EncryptUtils";

export default {
  components: {ShowNoDataView, DateChoiceDialog},
  data() {
    return {
      user: 1,
      selectDate: "",//显示选中的时间
      monthNum: "",//给接口的时间
      tabList: [
        {
          title: "发出的红包",
          finished: false,
          pageNo: 0,
          loading: false,
          finishedText: "没有更多了",
          pageSize: 10,
          contentList: [],
        }
      ],
    }
  },
  created() {
    //开始5
    try {
      //设置标题
      document.title = "红包记录"
      window.Android.setTitleStyle(JSON.stringify({
        titleBarBackgroundColor: '#FE484C',
        textColor: '#ffffff',
        showTitleBar: "false"
      }));
      window.Android.setStatusBarStyle(JSON.stringify({
        isDark: "false",
        statusBarBgColor: "#FE484C",
        showStatusBar: "false"
      }));
    } catch (error) {
      console.log("常规错误:" + error)
    }
    this.user = this.$route.query.user
    if (EncryptUtils.isEmpty(this.user)) {
      this.user = 0
    }
  },
  methods: {
    backPage() {
      window.Android.finish()
    },
    // 跳转到红包记录详情
    skipRedPackRecordPage(totalRedPacketUuid) {
      console.log("totalRedPacketUuid" + totalRedPacketUuid)
      if (this.user == 1) {
        this.$router.push({
          path: '/projectRedPackUserRecordDetail',
          query: {
            totalRedPacketUuid: totalRedPacketUuid,
          }
        });
      } else {
        this.$router.push({
          path: '/projectRedPackRecordDetail',
          query: {
            totalRedPacketUuid: totalRedPacketUuid,
          }
        });
      }
    },
    //组件回调 经过各种判断后返回加密后文字
    selectDateCallback(value) {
      console.log(value);
      this.selectDate = value
    },
    dateCallback(value) {
      console.log(value);
      this.monthNum = value
      this.tabList = [
        {
          title: "发出的红包",
          finished: false,
          pageNo: 0,
          loading: false,
          finishedText: "没有更多了",
          pageSize: 10,
          contentList: [],
        }
      ]
      this.getTabListContent(0)
    },
    choiceDate() {
      //显示日期
      this.$refs.controlDateDialog.controlDialog(true);
    },
    getTabListContent(index) {
      let tab = this.tabList[index]
      if (tab.finished) {
        return;
      }
      let data = {
        pageNo: tab.pageNo,
        pageSize: tab.pageSize,
        monthNum: this.monthNum
      }
      let apiObj
      if (this.user == 1) {
        apiObj = Api.listGotRedPacket(data)
      } else {
        apiObj = Api.listSendRedPacket(data)
      }
      if (apiObj) {
        apiObj.then(res => {
          if (res.code === 100) {
            let pageInfo;
            if (this.user == 1) {
              pageInfo = res.data.getRedPacketList
            } else {
              pageInfo = res.data.giveRedPacketList
            }
            let resData = pageInfo,
                list = resData.list;
            if (resData.isLastPage) { // 没有更多了
              tab.finished = true;
            }
            tab.contentList = tab.contentList.concat(this.handleList(list));
            tab.pageNo = tab.pageNo + 1;
            tab.loading = false;
          } else {
            Toast(res.msg);
          }
        })
      }
    },
    handleList(list) {
      list.forEach(item => {
        item['time'] = item.addTime.replace("T", " ")
        if (item.type == 3) {
          item['redPackName'] = Constants.kouLing
        } else {
          if (item.distributeType == 2) {
            item['redPackName'] = Constants.pinShouQi
          } else if (item.distributeType == 1) {
            item['redPackName'] = Constants.puTong
          }
        }
        if (item.totalRedPacketStatus == 1) {
          item['alreadyGrabDetail'] = "已领取" + item.alreadyGrabNum + "/" + item.number
        } else if (item.totalRedPacketStatus == 2) {
          item['alreadyGrabDetail'] = "已领完" + item.alreadyGrabNum + "/" + item.number
        } else if (item.totalRedPacketStatus == 3) {
          item['alreadyGrabDetail'] = "已过期" + item.alreadyGrabNum + "/" + item.number
        }
      })
      return list;
    },
    handleList2(list) {
      list.forEach(item => {
        item['redPackName'] = item.sendUserName
        item['time'] = item.getTime.replace("T", " ")
        item['alreadyGrabDetail'] = " "
      })
      return list;
    },
  }
}
</script>

<style scoped>
.body {
  background: #F8F8F8;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>
